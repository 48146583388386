<template>
  <AdminLayout
    title="Notes"
  >
    <template v-slot:app-bar>
      <v-btn
        color="primary"
        href="https://commonmark.org/help/"
        target="_blank"
      >
        Markdown
        <v-icon
          right
        >
          mdi-help-circle
        </v-icon>
      </v-btn>
    </template>

    <v-row>
      <v-col>
        <FormNote
          :type="PUBLIC"
          class="mb-3"
        />
        <ListNote
          :notes="publicNotes"
        />
      </v-col>
      <v-col>
        <FormNote
          :type="PRIVATE"
          class="mb-3"
        />
        <ListNote
          :notes="privateNotes"
        />
      </v-col>
    </v-row>
  </AdminLayout>
</template>

<script>
import AdminLayout from '../../layouts/AdminLayout'
import FormNote from '../../components/FormNote'
import ListNote from '../../components/ListNote'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    AdminLayout,
    FormNote,
    ListNote,
  },

  async created () {
    await this.fetchNotes()
  },

  computed: {
    ...mapGetters('notes', ['publicNotes', 'privateNotes']),
  },

  methods: {
    ...mapActions('notes', ['fetchNotes']),
  },
}
</script>
