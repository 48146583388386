<template>
  <editor-content
    :editor="editor"
  />
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import Highlight from '@tiptap/extension-highlight'
import Placeholder from '@tiptap/extension-placeholder'
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    type: Number,
  },

  components: {
    EditorContent,
  },

  data: () => ({
    editor: null,
  }),

  mounted () {
    this.editor = new Editor({
      extensions: [
        StarterKit,
        Highlight,
        Placeholder.configure({
          placeholder: `${this.type ? 'Private' : 'Public'} note`,
        }),
      ],
      onBlur: ({ editor }) => {
        if (editor.isEmpty) {
          return
        }
        this.addNote({
          text: editor.getHTML(),
          type: this.type,
          user_id: this.user.id,
        })
        editor.commands.clearContent()
      },
    })
  },

  computed: {
    ...mapGetters('user', ['user']),
  },

  methods: {
    ...mapActions('notes', ['fetchNotes', 'addNote']),
  },

  beforeDestroy () {
    this.editor.destroy()
  },
}
</script>

<style lang="scss">
/* Basic editor styles */
.ProseMirror {
  > * + * {
    margin-top: 0.75em;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0D0D0D;
    color: #FFF;
    font-family: 'JetBrainsMono', monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0D0D0D, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0D0D0D, 0.1);
    margin: 2rem 0;
  }

  p {
    margin-bottom: 0;
  }

  p.is-editor-empty:first-child::before {
    content: attr(data-placeholder);
    float: left;
    color: #adb5bd;
    pointer-events: none;
    height: 0;
  }

  outline: none !important;
}
</style>
