<template>
  <div class="ProseMirror">
    <v-card
      v-for="note in notes"
      :key="note.id"
    >
      <v-card-title>
        {{ note.user.name }}
        <v-icon
          v-if="note.user.id === user.id"
          class="ml-auto"
          color="red"
          @click="deleteNote(note)"
        >
          mdi-delete
        </v-icon>
      </v-card-title>
      <v-card-subtitle>
        {{ note.user.email }}<br>
        {{ (new Date(Date.parse(note.updated_at.split('.')[0]))).toUTCString() }}
      </v-card-subtitle>
      <v-card-text
        v-html="note.text"
      ></v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    notes: Array,
  },

  computed: {
    ...mapGetters('user', ['user']),
  },

  methods: {
    ...mapActions('notes', ['deleteNote']),
  },
}
</script>
